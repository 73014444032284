<template>
  <div class="mt-4">
    <div class="d-flex mt-4 align-items-center justify-content-between">
      <h1 class="page-title">
        <b-icon icon="person-fill" />
        {{ $t("account.accountTitle") }}
      </h1>
    </div>

    <b-overlay :show="loading" rounded="sm">
      <b-card class="mt-4 shadow-sm">
        <b-form @submit.prevent="saveChanges">
          <b-form-group
            id="group-active-role"
            :label="$t('account.twoFA')"
            label-class="font-weight-bold"
          >
            <b-form-checkbox v-model="twoFA">
              {{ $t("account.enableTwoFA") }}
            </b-form-checkbox>
          </b-form-group>

          <b-form-group
            id="group-active-role"
            :label="$t('account.activeRole')"
            label-class="font-weight-bold"
            label-for="active-role"
          >
            <b-form-select
              id="active-role"
              v-model="selected"
              :options="roles"
            />
          </b-form-group>

          <b-form-group
            id="group-active-role"
            :label="$t('account.showQre')"
            label-class="font-weight-bold"
          >
            <b-form-checkbox v-model="showAll">
              {{ $t("account.showQreText") }}
            </b-form-checkbox>
          </b-form-group>

          <div class="d-flex justify-content-end">
            <b-button variant="primary" pill type="submit">
              {{ $t("account.save") }}
            </b-button>
          </div>
        </b-form>
      </b-card>
    </b-overlay>
  </div>
</template>

<script>
import { getRefreshToken } from "axios-jwt";

export default {
  name: "Account",
  data() {
    return {
      selected: "",
      showAll: false,
      twoFA: false,
      roles: ["", ...this.$store.getters.roles],
      jwtToken: this.$store.getters.jwtToken,
      loading: true,
    };
  },
  mounted() {
    this.profileProvider();
  },
  methods: {
    async profileProvider() {
      this.loading = true;
      try {
        const {
          data: { activeRole, showAll, twoFAPreference },
        } = await this.$http.get(`${this.$cfg.BASE_IDENTITY_URL}/v0/me`);
        this.selected = activeRole;
        this.showAll = showAll;
        this.twoFA = twoFAPreference;
      } catch (err) {
        if (err.response) console.log(err.response);
      } finally {
        this.loading = false;
      }
    },
    async saveChanges() {
      this.loading = true;
      try {
        const {
          data: { activeRole, showAll },
        } = await this.$http.patch(`${this.$cfg.BASE_IDENTITY_URL}/v0/me`, {
          activeRole: this.selected,
          showAll: this.showAll,
          twoFAPreference: this.twoFA,
        });

        const res = await this.$http.put(
          this.$cfg.BASE_IDENTITY_URL + "/v0/refresh-login",
          {
            showAll: this.showAll,
            refreshToken: getRefreshToken(),
          }
        );
        await this.$store.dispatch("changeRole", res.data);

        this.selected = activeRole;
        this.showAll = showAll;

        this.$bvToast.toast("Changes were saved", {
          title: `Success`,
          toaster: "b-toaster-bottom-right",
          variant: "success",
          solid: true,
        });
      } catch (err) {
        if (err.response) console.log(err.response);
      } finally {
        this.loading = false;
      }
    },

    routeBack() {
      this.$router.push({ path: "/self" });
    },
  },
};
</script>

<style></style>
